import React from 'react';
import { getLayout } from '@components/common/Layout';
import { GetStaticProps } from 'next';
import {
  EditionsLocation,
  EditionsQuery,
  EditionsQueryVariables,
} from '@generated/codegen';
import { initializeApollo } from '@lib/graphql/client';
import { fetchEditionsPage } from '@lib/sanity.server';
import { EDITIONS_QUERY } from '@components/editions/graphql';
import { Editions, EditionsProps } from '@components/editions/Editions';

const editionsQueryVariables = {
  locations: [EditionsLocation.Editions],
};

export default function EditionsPage({ editionsPage }: EditionsProps) {
  return (
    <Editions
      itemPathname="/editions/[slug]"
      editionsPage={editionsPage}
      editionsQueryVariables={editionsQueryVariables}
    />
  );
}

export const getStaticProps: GetStaticProps<EditionsProps> = async ({
  preview,
}) => {
  const apolloClient = initializeApollo();
  const editionsPage = await fetchEditionsPage({ preview });
  if (!editionsPage) {
    return {
      notFound: true,
    };
  }
  const result = await apolloClient.query<
    EditionsQuery,
    EditionsQueryVariables
  >({
    query: EDITIONS_QUERY,
    variables: editionsQueryVariables,
  });

  if (result.error) {
    throw result.error;
  }
  return {
    revalidate: 30,
    props: {
      initialApolloState: apolloClient.cache.extract(),
      editionsPage,
    },
  };
};

EditionsPage.getLayout = (page: React.ReactNode) => getLayout(page);
